var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.openModalWarningFiscal
        ? _c("modal", {
            attrs: {
              message: _vm.getMessageFiscalAlert(),
              title: "Deseja continuar?",
              "check-message": "Li e estou ciente",
              "is-check-confirm": "",
              confirm: _vm.confirmAlertPlanFiscal,
              cancel: _vm.setCancelModalWarningFiscal,
            },
          })
        : _vm._e(),
      _c("checkout-header", {
        staticClass: "-mb-4",
        attrs: {
          "back-button-action": _vm.isDesktop ? false : _vm.backBtnAction,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                [
                  _c(
                    "span",
                    { attrs: { "data-cy": "choose-wizard_control-text" } },
                    [_vm._v(" 1 " + _vm._s(_vm.$t("checkout.of")) + " 2 — ")]
                  ),
                ],
                _c(
                  "strong",
                  { attrs: { "data-cy": "choose-wizard_control_choose-text" } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("checkout.choose_plan_recorrence")) +
                        " "
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", { staticClass: "recurrence-selection" }, [
        _c(
          "button",
          {
            class: { selected: _vm.recurrence === "1" },
            attrs: { "data-cy": "recurrence_btn_1" },
            on: {
              click: function ($event) {
                return _vm.setRecurrence("1")
              },
            },
          },
          [_vm._v(" Premium ")]
        ),
      ]),
      _vm.filteredPlans && _vm.filteredPlans.length
        ? _c(
            "div",
            { staticClass: "plan-selection" },
            _vm._l(_vm.filteredPlans, function (plan) {
              return _c(
                "div",
                {
                  key: plan.priceId,
                  staticClass: "plan-option",
                  class: {
                    highlighted: _vm.highlightPlan === plan.recurrencePeriod,
                  },
                  attrs: {
                    "data-cy": `card-selection-plan-${plan.recurrencePeriod}`,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.setPlan(plan)
                    },
                  },
                },
                [
                  _c("h3", [
                    _vm._v(
                      _vm._s(_vm.getRecurrencePeriod(plan.recurrencePeriod))
                    ),
                  ]),
                  _c("p", { staticClass: "description" }, [
                    _vm._v(_vm._s(_vm.getDescription(plan.name))),
                  ]),
                  _vm.recurrence !== "1"
                    ? _c("span", { staticClass: "original-price" }, [
                        _vm._v(
                          _vm._s(
                            `${plan.currencySymbol} ${_vm.getValueOriginalPrice(
                              plan
                            )}`
                          )
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "price-container" }, [
                    _c("div", { staticClass: "plan-price" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            `${plan.currencySymbol} ${_vm.getValueOriginalPrice(
                              plan
                            )}`
                          )
                        ),
                      ]),
                    ]),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "14px",
                          color: "#2c3e50",
                          "margin-top": "3px",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.getRecurrenceDescription(plan.recurrencePeriod)
                          )
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "subscribe-button",
                      class: {
                        "highlighted-button":
                          plan.recurrencePeriod === _vm.highlightPlan,
                        "default-button":
                          plan.recurrencePeriod !== _vm.highlightPlan,
                      },
                      attrs: {
                        "data-cy": `btn-subscribe-${plan.recurrencePeriod}`,
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.subscribe(plan)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.getButtonName(plan)) + " ")]
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }