<template>
  <div>
    <modal
      v-if="openModalWarningFiscal"
      :message="getMessageFiscalAlert()"
      title="Deseja continuar?"
      check-message="Li e estou ciente"
      is-check-confirm
      :confirm="confirmAlertPlanFiscal"
      :cancel="setCancelModalWarningFiscal"
    />
    <checkout-header
      :back-button-action="isDesktop ? false : backBtnAction"
      class="-mb-4"
    >
      <template v-slot:header>
        <template>
          <span data-cy="choose-wizard_control-text">
            1 {{ $t("checkout.of") }} 2 &mdash;
          </span>
        </template>
        <strong data-cy="choose-wizard_control_choose-text">
          {{ $t("checkout.choose_plan_recorrence") }}
        </strong>
      </template>
    </checkout-header>
    <div class="recurrence-selection">
      <button
        data-cy="recurrence_btn_1"
        @click="setRecurrence('1')"
        :class="{ selected: recurrence === '1' }"
      >
        Premium
      </button>
    </div>
    <div class="plan-selection" v-if="filteredPlans && filteredPlans.length">
      <div
        class="plan-option"
        v-for="plan in filteredPlans"
        :key="plan.priceId"
        :data-cy="`card-selection-plan-${plan.recurrencePeriod}`"
        :class="{ highlighted: highlightPlan === plan.recurrencePeriod }"
        @click="setPlan(plan)"
      >
        <h3>{{ getRecurrencePeriod(plan.recurrencePeriod) }}</h3>
        <p class="description">{{ getDescription(plan.name) }}</p>
        <span v-if="recurrence !== '1'" class="original-price">{{
          `${plan.currencySymbol} ${getValueOriginalPrice(plan)}`
        }}</span>
        <div class="price-container">
          <div class="plan-price">
            <span>{{
              `${plan.currencySymbol} ${getValueOriginalPrice(plan)}`
            }}</span>
          </div>
          <span style="font-size: 14px; color: #2c3e50; margin-top: 3px">{{
            getRecurrenceDescription(plan.recurrencePeriod)
          }}</span>
        </div>
        <button
          :class="{
            'highlighted-button': plan.recurrencePeriod === highlightPlan,
            'default-button': plan.recurrencePeriod !== highlightPlan,
          }"
          class="subscribe-button"
          :data-cy="`btn-subscribe-${plan.recurrencePeriod}`"
          @click.stop="subscribe(plan)"
        >
          {{ getButtonName(plan) }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CHECKOUT_PROFILE_EDIT_VIEW,
  HOME_VIEW,
  PLAN_SELECTOR_VIEW,
} from "@/router/constants";
import {
  CHECKOUT_MODULE,
  CHANGE_PLAN,
  GET_PLAN,
  GET_PLANS,
  GET_PLANS_MONTHLY,
  CHANGE_RECURRENCE,
} from "../store/constants/checkout";
import { mapGetters, mapActions } from "vuex";
import CheckoutHeader from "@/components/Checkout/Header.vue";
import { AccessOrigin } from "@/constants";
import Modal from "@/components/Modal.vue";
import { STORE, STORE_MODULE } from "@/store/constants/store";

export default {
  name: PLAN_SELECTOR_VIEW,
  components: {
    CheckoutHeader,
    Modal,
  },
  data() {
    return {
      selectedPlan: null,
      recurrence: "1",
      loading: false,
      highlightPlan: null,
      openModalWarningFiscal: false,
      priceMonthly: 0,
      recurrenceValue: 1,
    };
  },
  computed: {
    ...mapGetters({
      getPlan: `${CHECKOUT_MODULE}/${GET_PLAN}`,
      getPlans: `${CHECKOUT_MODULE}/${GET_PLANS_MONTHLY}`,
      getAllPlans: `${CHECKOUT_MODULE}/${GET_PLANS}`,
      getProfile: `${STORE_MODULE}/${STORE}`,
      lang: `language`,
      accessOrigin: "accessOrigin",
      isDesktop: `isDesktop`,
      eventGtm: "eventGtm",
      eventGtmWithData: "eventGtmWithData",
    }),
    filteredPlans() {
      if (!this.getAllPlans) return [];
      const plans = this.getAllPlans
        .map((plan) => {
          return {
            ...plan,
          };
        })
        .reverse();

      return plans;
    },
  },
  async mounted() {
    this.selectedPlan = this.getPlan?.recurrencePeriod;

    this.setHighlightPlan(1);
  },
  async created() {
    this.loading = true;

    if (!this.getPlans) {
      try {
        const priceId = this.$store.getters["store/store"].store.priceId;
        await this.getPlansAction({ priceId });
        await this.getAllPlansAction({ priceId });
      } catch (error) {
        const text =
          this.$t("migration.migrationError") +
          " " +
          this.$t("migration.suport");
        this.errorHandling(error, text);
      }
    }
    this.loading = false;
  },
  methods: {
    ...mapActions({
      changePlanAction: `${CHECKOUT_MODULE}/${CHANGE_PLAN}`,
      changePlanRecurrenceAction: `${CHECKOUT_MODULE}/${CHANGE_RECURRENCE}`,
      getPlansAction: `${CHECKOUT_MODULE}/${GET_PLANS_MONTHLY}`,
      getAllPlansAction: `${CHECKOUT_MODULE}/${GET_PLANS}`,
    }),
    backBtnAction() {
      return this.$router.push({ name: HOME_VIEW });
    },
    setRecurrencies() {
      this.changePlanRecurrenceAction(this.recurrence);
    },
    async nextStep() {
      this.setRecurrencies(this.selectedPlan);
      return this.$router.push({ name: CHECKOUT_PROFILE_EDIT_VIEW });
    },
    getValueOriginalPrice(plan) {
      let originalPrice = plan.price;

      return originalPrice.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    getFormatedPrice(price) {
      return price.toLocaleString("pt-BR", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    setPlan(plan) {
      this.highlightPlan = plan.recurrencePeriod;
      this.selectedPlan = plan.recurrencePeriod;
      this.changePlanAction(plan);
    },
    setRecurrence(recurrence) {
      this.recurrence = recurrence;
    },
    subscribe(plan) {
      this.setPlan(plan);
      this.nextStep();
    },
    getRecurrencePeriod(recurrence) {
      switch (recurrence) {
        case 1:
          return this.$t("recurrences.monthly");
        default:
          return this.$t("recurrences.annual");
      }
    },
    getRecurrenceDescription(recurrence) {
      switch (recurrence) {
        case 1:
          return this.$t("recurrences_freq.monthly");
        default:
          return this.$t("recurrences_freq.annual");
      }
    },
    getDiscount() {
      if (this.recurrence === "6") {
        return this.$t("checkout.save_discount") + " 9%";
      }
      return this.$t("checkout.save_discount") + " 17%";
    },
    getRecurrenceName() {
      let label = this.$t("checkout.charge") + " ";
      switch (this.recurrence) {
        case "6":
          label += this.$t("recurrences_freq.semiannual");
          break;
        case "12":
          label += this.$t("recurrences_freq.annual");
          break;
      }
      return label;
    },
    getButtonName() {
      return this.$t("checkout.subscribe_plan");
    },
    getDescription(plan) {
      switch (plan) {
        case "PRO V3":
          return this.$t("checkout.descriptionPro");
        case "PREMIUM V3":
          return this.$t("checkout.descriptionPremium");
        case "FISCAL V3":
          return this.$t("checkout.descriptionFiscal");
      }
    },
    setHighlightPlan(plan) {
      this.highlightPlan = plan ?? "PRO V3";
    },
    getMessageFiscalAlert() {
      if (!this.accessOrigin !== AccessOrigin.DESKTOP) {
        return this.$t("checkout.fiscal_warning");
      }
      this.setAlertFiscalPlan();
      this.$t("checkout.fiscal_warning");
    },
    setAlertFiscalPlan() {
      alert("@@planofiscal:https://www.programanex.com.br/nota-fiscal");
    },
    confirmAlertPlanFiscal() {
      this.openModalWarningFiscal = false;
      return this.$router.push({ name: CHECKOUT_PROFILE_EDIT_VIEW });
    },
    setCancelModalWarningFiscal() {
      this.openModalWarningFiscal = false;
    },
    errorHandling(error, message) {
      this.$fire({
        text: message,
        type: "error",
        timer: 6000,
        customClass: "alert-error-1",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
        setInterval(window.location.reload(), 6000);
      });
      throw new Error(error);
    },
  },
};
</script>

<style lang="scss">
.submit-label {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.plan-selection {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
  flex-wrap: wrap;
}

.original-price {
  text-decoration: line-through;
  /* color: red; */
  margin-right: 10px;
  font-size: 18px;
}

.plan-option {
  border: 2px solid #ddd;
  padding: 22px;
  border-radius: 8px;
  transition: background-color 0.3s;
  width: 32%;
  min-width: 270px;
  height: auto;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
}

.plan-option.highlighted {
  border-color: #0098f9;
}

.plan-option h3 {
  font-weight: bold;
  font-size: 1.5em;
  margin-bottom: 2px;
  color: #1e2a38;
}

.plan-option p {
  font-size: 1em;
  color: #64748b;
}

.plan-option p.description {
  font-size: 1em;
  color: #2c3e50;
  width: 80%;
  min-height: 2em;
  line-height: 1.5em;
  font-family: "Avenir", sans-serif;
}

.plan-option.selected {
  border-color: #007bff;
}

.price-container {
  display: flex;
  align-items: center;
}

.plan-price {
  font-size: 24px;
  color: #2c3e50;
  margin-right: 10px;
  font-weight: 600;
  font-family: "Avenir", sans-serif;
}

.discount {
  background-color: #d7efff;
  color: #1e2a38;
  font-size: 1em;
  padding: 5px 10px;
  border-radius: 5px;
}

.billing-cycle {
  font-family: "Avenir", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #64748b;
}

button.subscribe-button {
  border: none;
  border-radius: 4px 0 0 0;
  cursor: pointer;
  width: 146px;
  height: 52px;
  gap: 4px;
  opacity: 1;
  font-family: "Avenir", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: center;
}

button.subscribe-button.highlighted-button {
  background-color: #007bff;
  color: #fff;
}

button.subscribe-button.default-button {
  background-color: #fff;
  color: #000;
  border: 1px solid #ddd;
}

button.subscribe-button.default-button:hover {
  background-color: #f2f2f2;
  color: #000;
}

button.subscribe-button:hover {
  background-color: #0056b3;
  color: #fff;
}

.recurrence-selection {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.recurrence-selection button {
  background: none;
  border: none;
  padding: 6px 20px;
  cursor: pointer;
  font-size: 20px;
}

.recurrence-selection button.selected {
  color: #007bff;
  font-weight: bold;
  border-bottom: 2px solid #007bff;
  display: inline-block;
}

@media (max-width: 1023px) {
  .plan-option {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .plan-option {
    width: 100%;
  }
}
</style>
